@media screen and (max-width: 700px) {
  #features {
    padding: 20px;
    width: 157%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    padding: 10px;
    width: 157%;
  }

  #portfolio,
  #header {
    width: 157%;
  }
}
